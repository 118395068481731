import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'

const CompanyPhilosophyCard = ({ theme, title, body, ...other }) => (
  <Root theme={theme} {...other}>
    <Title variant="h4" dangerouslySetInnerHTML={{ __html: title }} />
    <Body variant="body2">{body}</Body>
  </Root>
)

const Root = styled.div`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${props => props.theme.scale[800]};
  @media ${mq.and(desktop, laptop, tabletL)} {
    padding: 40px;
  }
  @media ${mq.and(tabletS)} {
    padding: 40px 24px;
  }
  @media ${mq.and(mobile)} {
    padding: 32px 16px;
  }
`

const Title = styled(Typography)`
  flex-shrink: 0;
  flex-grow: 0;
`

const Body = styled(Typography)`
  flex-shrink: 1;
  flex-grow: 1;
  margin-top: 24px;
`

export default ThemeConsumer(CompanyPhilosophyCard)
