import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { desktop, laptop, tabletL, tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Section from '@/components/common/Section'
import Card from './AccessCard'

const CompanyAccess = ({ theme, ...other }) => (
  <StaticQuery
    query={query}
    render={ data => {
      const list = data.list.edges.map(el => el.node)
      return (
        <Root theme={theme} {...other}>
          <Section
            subtitle="ACCESS"
            title="アクセス"
            titleProps={{
              variant: 'h3'
            }}
            subTitleProps={{
              lang: 'en'
            }}
          >
            <List>
              {list.map((office, index) => (
                <Child key={index}>
                  <Card {...office} css={styles.Card} />
                </Child>
              ))}
            </List>
          </Section>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const List = styled.ul`
  @media ${mq.and(desktop, laptop, tabletL)} {
    display: flex;
    margin-top: 48px;
  }
  @media ${mq.and(tabletS)} {
    margin-top: 48px;
  }
  @media ${mq.and(mobile)} {
    margin-top: 24px;
  }
`

const Child = styled.li`
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: 50%;
  }
  @media ${mq.and(tabletS, mobile)} {
    margin-top: 8px;
  }
`

const styles = {
  Card: css`
    height: 100%;
  `
}

export default ThemeConsumer(CompanyAccess)

const query = graphql`
  query CompanyAccessQuery {
    list: allCompanyOfficeListYaml {
      edges {
        node {
          area
          headquarter
          postalCode
          address
          tel
          fax
          access
          map
        }
      }
    }
  }
`
